<template>
  <div>
    <h1 class="my-4">Статистика по карточкам блогеров</h1>
    <p class="mb-1">Дата от:</p>
    <b-form-datepicker class="mb-3" v-model="date" locale="ru"></b-form-datepicker>
    <p style="white-space: pre-wrap">{{ JSON.stringify(funnel, null, '\t') }}</p>
  </div>
</template>

<script>
import research from '@main/api/research';
import moment, { updateLocale } from 'moment';

export default {
  data: () => ({
    funnel: null,
    date: null,
  }),
  watch: {
    async date() {
      await this.updateData();
    },
  },
  methods: {
    async updateData() {
      const res = await research.instagramFunnel({ requests_from: this.date });
      this.funnel = res;
    },
  },
  async mounted() {
    this.date = new Date(moment().subtract(7, 'd'));
    await this.updateData();
  },
};
</script>
